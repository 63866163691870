/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AuthorizationSettingsDto,
  AuthorizationSettingsDtoListResponseModel,
  AuthorizationSettingsDtoModelResponse,
  ColumnFilter,
  GeneralStatus,
  SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AuthorizationSettings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags AuthorizationSettings
   * @name LoadList
   * @request GET:/AuthorizationSettings/Load
   */
  loadList = (
    query?: {
      InsuranceId?: number;
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<AuthorizationSettingsDtoListResponseModel, any>({
      path: `/AuthorizationSettings/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizationSettings
   * @name PostAuthorizationSettings
   * @request POST:/AuthorizationSettings/Add
   */
  postAuthorizationSettings = (data: AuthorizationSettingsDto, params: RequestParams = {}) =>
    this.request<AuthorizationSettingsDtoModelResponse, any>({
      path: `/AuthorizationSettings/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizationSettings
   * @name GetByIdList
   * @request GET:/AuthorizationSettings/GetById
   */
  getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<AuthorizationSettingsDtoModelResponse, any>({
      path: `/AuthorizationSettings/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizationSettings
   * @name DeleteDelete
   * @request DELETE:/AuthorizationSettings/Delete
   */
  deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<AuthorizationSettingsDtoModelResponse, any>({
      path: `/AuthorizationSettings/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthorizationSettings
   * @name UpdateCreate
   * @request POST:/AuthorizationSettings/Update
   */
  updateCreate = (data: AuthorizationSettingsDto, params: RequestParams = {}) =>
    this.request<AuthorizationSettingsDtoModelResponse, any>({
      path: `/AuthorizationSettings/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
