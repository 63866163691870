/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ColumnFilter,
  GeneralStatus,
  InsuranceContactDto,
  InsuranceContactDtoListResponseModel,
  InsuranceContactDtoModelResponse,
  SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class InsuranceContacts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags InsuranceContacts
   * @name PostInsuranceContacts
   * @request POST:/InsuranceContacts/Add
   */
  postInsuranceContacts = (data: InsuranceContactDto, params: RequestParams = {}) =>
    this.request<InsuranceContactDtoModelResponse, any>({
      path: `/InsuranceContacts/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InsuranceContacts
   * @name LoadList
   * @request GET:/InsuranceContacts/Load
   */
  loadList = (
    query?: {
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
      InsuranceId?:number;
    },
    params: RequestParams = {},
  ) =>
    this.request<InsuranceContactDtoListResponseModel, any>({
      path: `/InsuranceContacts/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InsuranceContacts
   * @name GetByIdList
   * @request GET:/InsuranceContacts/GetById
   */
  getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<InsuranceContactDtoModelResponse, any>({
      path: `/InsuranceContacts/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InsuranceContacts
   * @name DeleteDelete
   * @request DELETE:/InsuranceContacts/Delete
   */
  deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<InsuranceContactDtoModelResponse, any>({
      path: `/InsuranceContacts/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InsuranceContacts
   * @name UpdateCreate
   * @request POST:/InsuranceContacts/Update
   */
  updateCreate = (data: InsuranceContactDto, params: RequestParams = {}) =>
    this.request<InsuranceContactDtoModelResponse, any>({
      path: `/InsuranceContacts/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
