/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ColumnFilter,
  GeneralStatus,
  InsurancePlansDto,
  InsurancePlansDtoListResponseModel,
  InsurancePlansDtoModelResponse,
  SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class InsurancePlans<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags InsurancePlans
   * @name PostInsurancePlans
   * @request POST:/InsurancePlans/Add
   */
  postInsurancePlans = (data: InsurancePlansDto, params: RequestParams = {}) =>
    this.request<InsurancePlansDtoModelResponse, any>({
      path: `/InsurancePlans/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InsurancePlans
   * @name LoadList
   * @request GET:/InsurancePlans/Load
   */
  loadList = (
    query?: {
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
      InsuranceId?:number;
    },
    params: RequestParams = {},
  ) =>
    this.request<InsurancePlansDtoListResponseModel, any>({
      path: `/InsurancePlans/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InsurancePlans
   * @name GetByIdList
   * @request GET:/InsurancePlans/GetById
   */
  getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<InsurancePlansDtoModelResponse, any>({
      path: `/InsurancePlans/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InsurancePlans
   * @name DeleteDelete
   * @request DELETE:/InsurancePlans/Delete
   */
  deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<InsurancePlansDtoModelResponse, any>({
      path: `/InsurancePlans/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InsurancePlans
   * @name UpdateCreate
   * @request POST:/InsurancePlans/Update
   */
  updateCreate = (data: InsurancePlansDto, params: RequestParams = {}) =>
    this.request<InsurancePlansDtoModelResponse, any>({
      path: `/InsurancePlans/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
